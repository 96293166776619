
.bm-burger-button {
    position: fixed;
    z-index: 1002 !important;    
    width: 24px;
    height: 20px;
    left: 8px;
    top: 17px;
}

@media (min-width: 768px) {
    .bm-burger-button {
        top: 20px;
    }
}

.bm-burger-bars {
    background: #f7f7f7;
}

.bm-cross {
    background: #f7f7f7;
}

.bm-cross-button span {
    background-color: black;
    margin-top: -3px;
    margin-left: -3px;
}

.bm-morph-shape {
    fill: #f7f7f7;
}

.bm-menu {
    background: #DAE3F3;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.bm-menu a {
    color: #f7f7f7;
}

.bm-menu a:hover {
    color: #292b2c !important;
    background: #f7f7f7;
}

.bm-menu a:focus {
    color: #f7f7f7;
}

.bm-item-list a {
    padding: 0.2em;
}

.bm-item-list a span
{
    margin-left: 10px;
}

.bm-item:focus {
    outline: none;
}

.bm-menu {
    padding: 0 1em 0;
}

/*
a {
    padding: 1em;
}

i {
    font-size: 1.7em;
    vertical-align: middle;
    color: #282a35;
}
*/