/* Provide sufficient contrast against white background */

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

/* Works on Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #b3c6e7 transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: #b3c6e7;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #2F528F;
}

.opc-iframe {
    overflow: hidden;
    padding-top: 95%;
    position: relative;
}

.opc-iframe iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.opc-page-root {
    display: flex;
    flex-flow: column nowrap;
    height: 100%;
}

.opc-page-navbar {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1001;
    background-color: #2F5597;
    border-bottom: 10px solid #B4C7E7;
    font-size: large;
}

.opc-page-sidebar-titlebox {
    background-color: #B4C7E7;
    font-size: medium;
    border-bottom: 10px solid #B4C7E7;
}

.opc-page-footer {
    background-color: #B4C7E7;
    font-size: medium;
}

.opc-page-navbar .dropdown-menu {
    background-color: #203864;
}

.opc-page-navbar .navbar .nav-item,
.opc-page-navbar .navbar .nav-item a {
    color: whitesmoke !important;
}

.opc-page-navbar .navbar .nav-item span {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.opc-page-sidebar .selected {
    color: black !important;
    background-color: #B4C7E7 !important;
}

.opc-page-navbar .dropdown-item:hover,
.opc-page-navbar .navbar .nav-link:hover,
.opc-page-navbar .navbar .nav-item:hover {
    color: black !important;
    background-color: #B4C7E7 !important;
}

.opc-page-body {
    display: flex;
    flex-flow: row nowrap;
    height: 100%;
}

.opc-page-body .container {
    max-width: none;
}

.opc-page-content {
    flex: 1 1 auto;
    overflow: auto;
}

.opc-page-sidebar {
    flex: 1 1 200px;
    min-width: 200px;
    max-width: 200px;
    min-height: 110vh;
    background-color: #DAE3F3;
    color: black;
}

@media (min-width: 768px) {
    .opc-page-sidebar {
        min-width: 20%;
        max-width: 20%;
    }
    .opc-dialog {
        min-width: 300px;
    }
}

.opc-dialog {
    min-width: 100px;
    border-width: 6px !important;
}
@media (min-width: 576px) {
    .opc-dialog {
        min-width: 300px;
        max-width: 500px;
    }
}
@media (min-width: 768px) {
    .opc-dialog {
        min-width: 550px;
        max-width: 700px;
    }
}
@media (min-width: 1200px) {
    .opc-dialog {
        min-width: 900px;
    }
}


/*
Extra small
576px 	Small
576px 	Medium
768px 	Large
992px 	Extra large
1200px
.opc-page-sidebar {
    min-height: 110vh;
    background-color: #DAE3F3;
    color: black;
    display: flex;
    flex-flow: row nowrap;
}
*/

.opc-page-sidebar .opc-page-sidebar-content {
    overflow-x: hidden;
    overflow-y: auto;
}

.opc-page-sidebar .nav-link {
    color: black !important;
}

div .opc-box {
    border-radius: unset;
    border-width: 1px !important;
    border: solid;
}

div .opc-box-rounded {
    border-radius: 6px;
    border-width: 3px !important;
    border: solid;
}

div .opc-box-light {
    border-radius: unset;
    border-right: 0px !important;
    border-left: 0px !important;
    border-width: 1px !important;
    border: solid;
}

div .opc-button-row input:hover,
div .opc-button-row input:focus,
div .opc-button-row input:active {
    color: #FFFFFF !important;
    background-color: #000000 !important;
}

input[type=file] {
    position: absolute;
    z-index: -1;
    color: #000000 !important;
}

input[type=button],
.MuiButton-root,
.opc-upload label {
    color: #000000 !important;
    border: 2px solid #2F5597 !important;
    margin: 1px !important;
    padding: 1px !important;
    padding-left: 4px !important;
    padding-right: 4px !important;
    border-radius: 2px;
    background-color: #DAE3F3 !important;
    transition: 0.5s;
    text-transform: none !important;
    min-width: 50px;
}

input[type=button]:hover,
.MuiButton-root:hover,
.opc-upload label:hover {
    color: #FFFFFF !important;
    background-color: #2F5597 !important;
}

button:disabled,
.MuiButton-root:disabled {
    color: white !important;
    background-color: grey !important; 
    border-color: darkgray !important;
}

div .opc-blue {
    color: #000000;
    border-color: #2F528F !important;
    background-color: #DAE3F3;
}

div .opc-grey {
    color: #000000;
    border-color: #000000 !important;
    background-color: #F2F2F2;
}

div .opc-dark-blue {
    color: #FFFFFF;
    border-color: #2F528F !important;
    background-color: #2F5597;
}

div .opc-dark-grey {
    color: #FFFFFF;
    border-color: #666666 !important;
    background-color: #666666
}

div .opc-dark-yellow {
    color: #FFFFFF;
    border-color: #5c5448 !important;
    background-color: #5c5448;
}

div .opc-red {
    color: #FF0000;
    border-color: #FF0000 !important;
    background-color: #f3dae3;
}

div .opc-green {
    color: #000000;
    border-color: #548235 !important;
    background-color: #e3f3da;
}

div .opc-orange {
    color: #000000;
    border-color: #C55A11 !important;
    background-color: #FBE5D6;
}

div .opc-highlight {
    color: #000000;
    border-width: 2px !important;
    border-color: #BF9000 !important;
    background-color: #FFF2CC;
}

div .opc-yellow {
    color: #000000;
    border-color: #8f6c2f !important;
    background-color: #f3eada;
}

div .opc-light-yellow {
    color: #000000;
    border-color: #BF9000 !important;
    background-color: #FFF2CC;
}
